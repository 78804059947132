import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import { createPopper } from '@popperjs/core';
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import "../Assets/Css/Table.css";
import axios from "axios";
import { Alert, Form } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import Select from 'react-select'
import { MultiSelect } from "react-multi-select-component";

import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { axiosConfig } from "../axiosAPI";

function Specialties() {
  const [selected, setSelected] = useState([]);
  const auth = useAuth();
  const [Specialties, setSpecialties] = useState({});
  const [master, setMaster] = useState([null]);
  const [parentId, setParentId] = useState("");
  const [loading, setLoading] = useState(false);
const [options,setOptions]=useState([])
  const getData = async () => {
    try {
      setLoading(true);
      const response = await axiosConfig.get(
        `https://api.wabell.net/api/v1/specialities/tree`,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setSpecialties(response.data.data);
      // console.log(response.data.data);
      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const getMaster = async () => {
    try {
      setLoading(true);
      const response = await axiosConfig.get(
        `https://api.wabell.net/api/v1/users/masters/speciality`,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setMaster(response.data.data);
      // console.log(response.data.data);
      setLoading(false);

      setOptions(response.data.data.map(item=>({
        value:item.uuid,
        label:item.fullName

      })))
      
// console.log(options.length> 0&&JSON.stringify(options))
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      setLoading(false); // Stop loading even if there's an error
    }
  };

  const [visible, setVisible] = useState(false);
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add Specialties</span>
    </div>
  );
  const footerContent = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          handleSubmit();
          setVisible(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );

  const [visible2, setVisible2] = useState(false);
  const headerElement2 = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Edit Specialties</span>
    </div>
  );
  const footerContent2 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible2(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          editUser();
          setVisible2(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );

  const [formData, setFormData] = useState({
    title_En: "",
    title_Ar: "",
    masterUuids: [],
    // parentUuid: `${parentId}`
  });

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    var data = {
      ...formData ,
      masterUuids: selected.map((el)=>el.value)
    };

    if (parentId) {
      data = {
        ...data,
        parentUuid: parentId
      }
    }

    const response = await axiosConfig
      .post("https://api.wabell.net/api/v1/specialities", data, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const deleteUser = async (uuid) => {
    // wait for 1 second before deleting
    const response = await axiosConfig
      .delete("https://api.wabell.net/api/v1/specialities/" + uuid, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Can't delete this Specialty",
        });
      });
  };

  const editUser = async () => {
    const response = await axiosConfig
      .put(
        "https://api.wabell.net/api/v1/specialities/" + formData?.uuid,
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = localStorage.getItem('permissions');
    if (items) {
      setItems(items);
    }
  }, []);

  const Create = "p_sp0002"; 
  const Update = "p_sp0003"; 
  const Delete = "p_sp0004"; 

  // Check if targetValue is included in items
  const includesCreate = items.includes(Create);
  const includesUpdate = items.includes(Update);
  const includesDelete = items.includes(Delete);

  useLayoutEffect(() => {
    getData();
    getMaster() 
  }, []);

  if (loading) {
    return <div>Loading...</div>; 
  }
  const a = selected.map((el)=>el.value)
  console.log(a);
  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="table">
              {includesCreate ? <a className="add" onClick={() => setVisible(true)}>
                <i className="fa fa-plus"></i>
              </a> : "" }
              <div className="mt-5">
                <SimpleTreeView>
                  {Specialties.length > 0 ? (
                    Specialties.map((x, index) => (
                      <TreeItem
                        itemId={x.uuid}
                        label={
                          <div className="tree-table">
                            {x.title}
                            <div className="tree-action">
                              {includesCreate ? <a className="" onClick={() => { setVisible(true); setParentId(x.uuid) }}>
                                <i className="fa fa-plus"></i>
                              </a> : "" }
                              {includesUpdate ? <a
                                className="edit"
                                onClick={(e) => {
                                  setVisible2(true);
                                  setFormData(x);
                                }}
                              >
                                <i className="fa fa-pen"></i>
                              </a> : "" }
                              {includesDelete ? <a
                                className="delete"
                                onClick={(e) => {
                                  deleteUser(x.uuid);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </a> : "" }
                            </div>
                          </div>
                        }
                      >
                        {x?.children?.length > 0
                          ? x?.children?.map((y, index) => (
                            <TreeItem
                              itemId={y.uuid}
                              label={
                                <div className="tree-table">
                                  {y.title}
                                  <div className="tree-action">
                                    {includesCreate ? <a
                                      className=""
                                      onClick={() => { setVisible(true); setParentId(y.uuid) }}
                                    >
                                      <i className="fa fa-plus"></i>
                                    </a> : "" }
                                    {includesUpdate ? <a
                                      className="edit"
                                      onClick={(e) => {
                                        setVisible2(true);
                                        setFormData(y);
                                      }}
                                    >
                                      <i className="fa fa-pen"></i>
                                    </a> : "" }
                                    {includesDelete ? <a
                                      className="delete"
                                      onClick={(e) => {
                                        deleteUser(y.uuid);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </a> : "" }
                                  </div>
                                </div>
                              }
                            >
                              {y?.children?.length > 0
                                ? y?.children?.map((p, index) => (
                                  <TreeItem
                                    itemId={p.uuid}
                                    label={
                                      <div className="tree-table">
                                        {p.title}
                                        <div className="tree-action">
                                          {includesCreate ? <a
                                            className=""
                                            onClick={() => { setVisible(true); setParentId(p.uuid) }}
                                          >
                                            <i className="fa fa-plus"></i>
                                          </a> : "" }
                                          {includesUpdate ? <a
                                            className="edit"
                                            onClick={(e) => {
                                              setVisible2(true);
                                              setFormData(p);
                                            }}
                                          >
                                            <i className="fa fa-pen"></i>
                                          </a> : "" }
                                          {includesDelete ? <a
                                            className="delete"
                                            onClick={(e) => {
                                              deleteUser(p.uuid);
                                            }}
                                          >
                                            <i className="fa fa-trash"></i>
                                          </a> : "" }
                                        </div>
                                      </div>
                                    }
                                  >
                                    {p?.children?.length > 0
                                      ? p?.children?.map((u, index) => (
                                        <TreeItem
                                          itemId={u.uuid}
                                          label={
                                            <div className="tree-table">
                                              {u.title}
                                              <div className="tree-action">
                                                {includesCreate ? <a
                                                  className=""
                                                  onClick={() => { setVisible(true); setParentId(u.uuid) }
                                                  }
                                                >
                                                  <i className="fa fa-plus"></i>
                                                </a> : "" }
                                                {includesUpdate ? <a
                                                  className="edit"
                                                  onClick={(e) => {
                                                    setVisible2(true);
                                                    setFormData(u);
                                                  }}
                                                >
                                                  <i className="fa fa-pen"></i>
                                                </a> : "" }
                                                {includesDelete ? <a
                                                  className="delete"
                                                  onClick={(e) => {
                                                    deleteUser(u.uuid);
                                                  }}
                                                >
                                                  <i className="fa fa-trash"></i>
                                                </a> : "" }
                                              </div>
                                            </div>
                                          }
                                        >
                                          {u?.children?.length > 0
                                            ? u?.children?.map(
                                              (q, index) => (
                                                <>
                                                  <TreeItem
                                                    itemId={q.uuid}
                                                    label={
                                                      <div className="tree-table">
                                                        {q.title}
                                                        <div className="tree-action">
                                                          {includesCreate ? <a
                                                            className=""
                                                            onClick={() => {
                                                              setVisible(
                                                                true
                                                              ); setParentId(q.uuid)
                                                            }
                                                            }
                                                          >
                                                            <i className="fa fa-plus"></i>
                                                          </a> : "" }
                                                          {includesUpdate ? <a
                                                            className="edit"
                                                            onClick={(
                                                              e
                                                            ) => {
                                                              setVisible2(
                                                                true
                                                              );
                                                              setFormData(
                                                                q
                                                              );
                                                            }}
                                                          >
                                                            <i className="fa fa-pen"></i>
                                                          </a> : "" }
                                                          {includesDelete ? <a
                                                            className="delete"
                                                            onClick={(
                                                              e
                                                            ) => {
                                                              deleteUser(
                                                                q.uuid
                                                              );
                                                            }}
                                                          >
                                                            <i className="fa fa-trash"></i>
                                                          </a> : "" } 
                                                        </div>
                                                      </div>
                                                    }
                                                  ></TreeItem>
                                                </>
                                              )
                                            )
                                            : ""}
                                        </TreeItem>
                                      ))
                                      : ""}
                                  </TreeItem>
                                ))
                                : ""}
                            </TreeItem>
                          ))
                          : ""}
                      </TreeItem>
                    ))
                  ) : (
                    <p>No Specialties available</p>
                  )}
                </SimpleTreeView>
              </div>
            </div>
          </div>

          <Dialog
            visible={visible}
            modal
            header={headerElement}
            footer={footerContent}
            style={{ width: "50rem" }}
            onHide={() => {
              if (!visible) return;
              setVisible(false);
            }}
          >
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Title En</Form.Label>
                    <Form.Control
                      type="text"
                      name="title_En"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Title Ar</Form.Label>
                    <Form.Control
                      type="text"
                      name="title_Ar"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Masters</label>
                    <MultiSelect
                      options={options}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </Dialog>
          <Dialog
            visible={visible2}
            modal
            header={headerElement2}
            footer={footerContent2}
            style={{ width: "50rem" }}
            onHide={() => {
              if (!visible2) return;
              setVisible2(false);
            }}
          >
            <Form onSubmit={editUser}>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Name En</Form.Label>
                    <Form.Control
                      type="text"
                      name="title_En"
                      placeholder=""
                      value={formData.title_En}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Name Ar</Form.Label>
                    <Form.Control
                      type="text"
                      name="title_Ar"
                      placeholder=""
                      value={formData.title_Ar}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Masters</label>
                    <MultiSelect
                      options={options}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </Dialog>
        </div>
      </div>
    </>
  );
}

export default Specialties;
