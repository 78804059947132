import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

import axios from "axios";
import Swal from "sweetalert2";
import { axiosConfig } from "../axiosAPI";
import { useAuth } from "../AuthProvider";
import { useNavigate } from "react-router-dom";
import All from "./All";
import Archive from "./Archive";

function SpecialtiesRequest() {


  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <Tabs className="mb-3">
              <TabList>
                <div className="row">
                    <Tab className="col-6">
                      <div className="free">
                        <h2>All</h2>
                      </div>
                    </Tab>
                    <Tab className="col-6">
                      <div className="free">
                        <h2>Archive</h2>
                      </div>
                    </Tab>
                </div>
              </TabList>
              
              <TabPanel>
                <All />
              </TabPanel>
              <TabPanel>
                <Archive />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpecialtiesRequest;
