import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

import axios from "axios";
import Swal from "sweetalert2";
import { axiosConfig } from "../axiosAPI";
import { useAuth } from "../AuthProvider";
import { useNavigate } from "react-router-dom";

function All() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://api.wabell.net/api/v1/users/speciality-requests",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setCustomers(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end mb-3 mt-3">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };

  const Archive = async (uuid) => {
    const response = await axiosConfig
      .put(`https://api.wabell.net/api/v1/users/speciality-requests/${uuid}/archive`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };


  const gotToNewPage = (rowData) => {
    return (
      <>{includesArchive ? <button onClick={(e) => {Archive(rowData.uuid)}} >Archive</button> : "" }</>
    );
  };

  
  const dateCreated = (rowData) => {
    const dateString = rowData.dateCreated;
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return (
      <>
        <div className="action-table">
          <span>{formattedDate}</span>
        </div>
      </>
    );
  };
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = localStorage.getItem('permissions');
    if (items) {
      setItems(items);
    }
  }, []);

  console.log(items);

  const ArchiveView = "p_u0006";

  // Check if targetValue is included in items
  const includesArchive = items.includes(ArchiveView);



  useEffect(() => {
    getData().then((data) => {
      setLoading(false);
    });
  }, []);


  return (
    <>
        <DataTable
        value={customers}
        editMode="row"
        paginator
        showGridlines
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: "700px" }}
        loading={loading}
        //   dataKey="id"
        filters={filters}
        globalFilterFields={[
            "masterFullName",
            "message",
            "dateCreated",
        ]}
        header={header}
        emptyMessage="No customers found."
        onRowEditComplete={onRowEditComplete}
        >
        <Column
            field="masterFullName"
            header="Name"
            style={{ width: "33.3333333" }}
        ></Column>
        <Column
            field="message"
            header="Message"
            style={{ width: "33.3333333" }}
        ></Column>
        <Column
            field="uuid"
            body={dateCreated}
            header="date"
            style={{ width: "33.3333333" }}
        ></Column>
        <Column
            field="uuid"
            body={gotToNewPage}
            header="Details"
            style={{ width: "20%" }}
        ></Column>
        </DataTable>
    </>
  );
}

export default All;
